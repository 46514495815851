import React from 'react';

import { ReactComponent as Bot } from '../../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../../assets/icons/top-wave.svg';
import image from '../../assets/images/b-corp-logo.png';
import { ScContainer } from '../../components/container/styled';
import { useMatchMedia } from '../../hooks/use-match-media';
import { ScAboutUsHeroContainer, ScAboutUsHeroWrapper } from '../AboutUs/styled';

import {
	ScBcorpHeroContent,
	ScBcorpText,
	ScBcorpTextBlock,
	ScBcorpTextBlockContent,
} from './styled';

export const BCorpHero = () => {
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);
	return (
		<ScAboutUsHeroContainer>
			<Top />
			<ScAboutUsHeroWrapper>
				<ScContainer>
					<ScBcorpHeroContent>
						<h3>Proud to be a BCorp</h3>
						<p>
							Gogeta is the only salary sacrifice benefits provider to be BCorp certified. Certified
							B Corporations are businesses that meet the highest standards of social and
							environmental performance, public transparency, and legal accountability to balance
							profit and purpose. Here’s why we achieved BCorp status.
						</p>
						<ScBcorpText>
							<ScBcorpTextBlock>
								<h3>1. Treating partners fairly</h3>
								<ScBcorpTextBlockContent>
									<div>
										<p>
											We started Gogeta because we saw first hand the damage caused by the high
											commissions charged by big, legacy cycle to work providers to independent bike
											shops. We flipped the cycle to work business model on its head, and created a
											scheme that offered a fair deal for retailers and the best savings for
											employees.
										</p>
									</div>
									<div>
										<p>
											You can read more about why Gogeta’s founder started Gogeta here. Now we offer
											a range of salary sacrifice benefits but our commitment to treating our
											partners fairly remains. We believe salary sacrifice benefits should benefit
											everyone, employees, employees and the partners (like bike shops and
											nurseries) that we work with.
										</p>
									</div>
								</ScBcorpTextBlockContent>
							</ScBcorpTextBlock>
							<ScBcorpTextBlock>
								<h3>2. Creating a positive social impact</h3>
								<ScBcorpTextBlockContent>
									<div>
										<p>
											We focus on offering salary sacrifice benefits that create a positive social
											impact.
										</p>
										<p>
											<b>Helping nurseries thrive</b> - It’s no secret that nurseries across the UK
											are under huge financial pressure. Gogeta benefits nurseries because employers
											make a financial contribution to the nursery of every parent using the scheme,
											in addition to their nursery fees. This is a new and sustainable model for
											childcare in the UK, where employers take an active interest in and contribute
											to the childcare provisions that enable their employees to participate in the
											workforce. The employer, parent and the nursery work{' '}
											{!isDesktop &&
												'together to decide how this money is spent, creating a level of connection between employers and nurseries that has previously been unheard of in the UK.'}
										</p>
									</div>
									<div>
										{isDesktop && (
											<p>
												together to decide how this money is spent, creating a level of connection
												between employers and nurseries that has previously been unheard of in the
												UK.
											</p>
										)}
										<p>
											<b>Getting more people cycling</b> - Gogeta Bike makes it more affordable for
											people to get into cycling. Cycling has huge positive impacts at a personal
											and societal level. Cycling reduces your carbon footprint and improves your
											physical and mental health. Being more physically active reduces employee sick
											days. People who cycle are more likely to shop local, supporting independent
											businesses and boosting the high street and local town centres.
										</p>
									</div>
								</ScBcorpTextBlockContent>
							</ScBcorpTextBlock>
							<ScBcorpTextBlock>
								<h3>3. Helping hardworking people thrive not just survive</h3>
								<ScBcorpTextBlockContent>
									<div>
										<p>
											Cost of living pressures have impacted everyone in the UK in recent years.
											Gogeta’s salary sacrifice benefits help people make their payslips go further
											by paying for things out of their gross salary, meaning they have more take
											home pay.
										</p>
										<p>
											<b>Making childcare more affordable</b> - the UK has some of the highest
											childcare costs in the world, causing working parents real financial hardship
											and stress. Gogeta Nursery helps people make big savings on their nursery
											costs, making a huge difference to their monthly outgoings and helping make
											working and parenting add up.
										</p>
									</div>
									<div>
										<img src={image} alt="b-corp hands" />
									</div>
								</ScBcorpTextBlockContent>
							</ScBcorpTextBlock>
						</ScBcorpText>
					</ScBcorpHeroContent>
				</ScContainer>
			</ScAboutUsHeroWrapper>
			<Bot />
		</ScAboutUsHeroContainer>
	);
};
